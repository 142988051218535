import React from 'react';
import Card from 'react-bootstrap/Card';
import Jumbotron from 'react-bootstrap/Jumbotron';

import createImg from './create.png';
import consoleImg from './console.png';
import emailImg from './email.png';

import {
  Link
} from 'react-router-dom'

class InfoPage extends React.Component {
  
  render() {
    return (
      <div className="aboutContainer">  
        <Jumbotron>
          <h1>Book that hard-to-get campsite</h1>
          <p>
            cmpcmpcmp.com monitors busy campgrounds for availability so you don't have to.  
          </p>
          <p>
            <Link className="tryButton btn btn-success" role="button" to="/console">Try Free!</Link>
          </p>
        </Jumbotron>
        <div className="aboutContainer">
          <Card style={{ width: '18rem', height: '24rem' }}>
            <Card.Header>Create your site checks</Card.Header>
            <Card.Img className="aboutImg" variant="bottom" src={createImg} />
            <Card.Body>
              <Card.Text>
                Pick the dates, campground, and site type and let us take care of the rest!
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: '18rem', height: '24rem' }}>
            <Card.Header>Management console</Card.Header>
            <Card.Img className="aboutImg" variant="left" src={consoleImg} />
            <Card.Body>
              <Card.Text>
                See all the site checks you have going at a glance
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: '18rem', height: '24rem' }}>
            <Card.Header>Email report</Card.Header>
            <Card.Img className="aboutImg" variant="left" src={emailImg} />
            <Card.Body>
              <Card.Text>
                Get notified within 5 minutes when a campsite becomes availabile. You'll get a link directly to the booking site.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>);
  }
}

export default InfoPage;