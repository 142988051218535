import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Moment from 'react-moment';

import moment from 'moment';

class Reservation extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      notificationPreference: this.props.reservation.notificationPreference,
    };
    this.handleDeactivate = this.handleDeactivate.bind(this);
    this.handleReactivate = this.handleReactivate.bind(this);
    this.handleNotificationPreferenceChange = this.handleNotificationPreferenceChange.bind(this);
  }

  handleDeactivate(e) {
    let docRef = this.props.firestore
        .collection("reservations")
        .doc(this.props.docId);
    docRef.get()
        .then(doc => {
          if (doc.exists) {
              let reservation = doc.data();
              reservation.isActive = false;
              return docRef.set(reservation);
          }
        })
        .then(unused => this.props.onReservationChanged());
  }

  handleReactivate(e) {
    let docRef = this.props.firestore
        .collection("reservations")
        .doc(this.props.docId);
    docRef.get()
        .then(doc => {
          if (doc.exists) {
              let reservation = doc.data();
              reservation.isActive = true;
              return docRef.set(reservation);
          }
        })
        .then(unused => this.props.onReservationChanged());
  }

  handleNotificationPreferenceChange(e) {
    let docRef = this.props.firestore
        .collection("reservations")
        .doc(this.props.docId);
    let selection = e.target.value;
    docRef.get()
        .then(doc => {
          if (doc.exists) {
              let reservation = doc.data();
              reservation.notificationPreference = selection;
              return docRef.set(reservation);
          }
        });
    this.setState({notificationPreference: selection});
  }

  render() {
    const {reservation} = this.props;
    return (
      <Row className="reservationRow">
        <Col md={10} sm={9} xs={8}>
          <Row>
            <Col xs={12} sm={3}>{reservation.campgroundName}</Col>
            <Col xs={12} sm={3}><div className="reservationDate"><Moment format="MMM DD">{reservation.startDate.toDate()}</Moment>-<Moment format="MMM DD, YYYY">{reservation.endDate.toDate()}</Moment></div></Col>
            <Col xs={12} sm={3}>{reservation.lastRun ?
            <span className="lastRun">Last run <Moment fromNow>{reservation.lastRun.toDate()}</Moment></span> 
                : <span className="lastRun">Will run soon</span> 
              }
            </Col>
            <Col xs={12} sm={3}>
              <span className="notify-preference-label">Notify for: </span> 
              <select className="notify-preference" value={this.state.notificationPreference} onChange={this.handleNotificationPreferenceChange}>
                <option value="any">any day in range</option>
                <option value="all"> all days available</option>
              </select>
            </Col>
          </Row>
        </Col>
        <Col className="reservationActions" md={2} sm={3} xs={4}>
          {moment(reservation.startDate.toDate()).diff(moment(new Date()),'days') < 0 ? 
            <span>Archived</span> :
            reservation.isActive ? 
              <Button variant="warning" onClick={this.handleDeactivate}>Pause</Button> :
              <Button variant="primary" onClick={this.handleReactivate}>Activate</Button>
          }
        </Col>
      </Row>);
  }
}

export default Reservation;