import React from 'react';

class InfoPage extends React.Component {
  
  render() {
    return (
      <div className="infoContainer">  
          <h4>How do I use it?</h4>
          <p>
            You tell us the dates you want to go camping, the campground you want and the tool will do the mundane job of 
            checking the campground booking site for you once every 5 minutes. When we spot an unclaimed campsite, we'll
            send you a report via email so you can book it. Remember, the tool doesn't hold the site for you, it just 
            notifies you when there's availability. We'll also send you a report if the availability disappears, likely 
            because someone has beat you to the reservation.
          </p>
          <h4>Why does this work?</h4>
          <p>
            Typically, campers book out popular campgrounds months in advance but plans always change so some will cancel
            their reservations. We monitor those cancellations for you so you can swoop in and grab the site!
          </p>
          <h4>What happens if I request a check for a long period of time?</h4>
          <p>
            You'll get an email report for any day that opens up during your trip. You can decide whether to book that right 
            away or wait until there's a cancellation for a longer block. There's a special email with the words "ALL REQUESTED DAYS"
            in ALL CAPS when we find availability for the entire length of your trip.
          </p>
          <h4>Why is the campground I want to visit not listed?</h4>
          <p>
            Please check that the campground is bookable on <a target="_blank" rel="noopener noreferrer" href="https://recreation.gov">recreation.gov</a> and that the spelling
            matches what you typed into the filter. Also check that you've selected the right filter type (park or campground).
          </p>
          <h4>Why does the booking site tell me there's no availability when I try to book?</h4>
          <p>
            Most likely someone beat you to the reservation. Better luck next time!
          </p>
          <h4>Why am I not getting email?</h4>
          <p>
            You should get a confirmation email once you create a site check. The email is sent to the address you used when you sign-in. 
            If you don't see it in your email, maybe it ended up in your spam folder.
          </p>
          <h4>What's a site check?</h4>
          <p>
            Each campground and period of time you want to stay is represented on cmpcmpcmp.com as a site check. You can use the 'pause' and 'activate'
            buttons to control whether we continue checking for availability. This is useful if you've booked your site (congrats!) and you want to stop
            getting notified when more availability comes up.
          </p>
          { process.env.REACT_APP_LAUNCH_STATUS !== "free" &&
            <div>
              <h4>Why do you charge even if there's no availability found?</h4>
              <p>
                The site uses resources to check for you regardless of whether it finds anything. Think of it like hiring someone
                to refresh the booking site for you every 5 minutes.
              </p>
              <h4>Is there a free trial?</h4>
              <p>
                You start some credits when you create an account. That should let you create a short site check to try it out!
              </p>
              <h4>Can I refund my credits?</h4>
              <p>
                Sorry, all sales are final. You are supporting development of this tool though so many thanks!
              </p>
              <h4>Are you profiting from national parks?</h4>
              <p>
                We are increasing utilization of the parks so it's a win-win situation. We want to make sure as many people have the opportunity to enjoy the outdoors as possible.
              </p>
              <h4>Are you doing this for profit?</h4>
              <p>
                Nope, you payment will cover maintenance and proceeds goes towards <a target="_blank" rel="noopener noreferrer" href="http://www.accessfund.org">www.accessfund.org/</a>.
              </p>
            </div>
          }
          <h4>Where do I report issues?</h4>
          <p>
            Please let me know at <a href="mailto:info@cmpcmpcmp.com">info@cmpcmpcmp.com</a>.
          </p>
      </div>);
  }
}

export default InfoPage;