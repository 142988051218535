import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavItem from 'react-bootstrap/NavItem';

import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link
} from 'react-router-dom'

import CampgroundForm from './campgroundForm';
import CheckoutForm from './checkoutForm';
import HomePage from './homePage';
import InfoPage from './infoPage';
import Reservation from './reservation';
import UserForm from './userForm';

import {Elements, StripeProvider} from 'react-stripe-elements';

import './App.css';
import googleSignIn from './btn_google_light_normal_ios.svg';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import firebaseConfig from './firebaseConfig';
import withFirebaseAuth from 'react-with-firebase-auth'

import "react-datepicker/dist/react-datepicker.css";

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAppAuth = firebaseApp.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider()
};
var firestore = firebaseApp.firestore();
const INIT_CREDIT_AMOUNT = process.env.REACT_APP_LAUNCH_STATUS === "free" ? 50 : 8;

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      reservations: [],
      user: null,
      signInModalShow: false,
      reservationModalShow: false,
      showCreateUser: false,
      signInErrorMessage: "",
      emailVerificationSent: false,
      credits: 0
    };

    this.loadReservations = this.loadReservations.bind(this);
    this.handleReservationSubmit = this.handleReservationSubmit.bind(this);
    this.handleReservationModalClose = this.handleReservationModalClose.bind(this);
    this.handleReservationModalOpen = this.handleReservationModalOpen.bind(this);
    this.handleSignInModalClose = this.handleSignInModalClose.bind(this);
    this.handleSignInModalOpen = this.handleSignInModalOpen.bind(this);
    this.handleCreateUser = this.handleCreateUser.bind(this);
    this.handleSignInUser = this.handleSignInUser.bind(this);
    this.showCreateUser = this.showCreateUser.bind(this);
    this.showSignIn = this.showSignIn.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
  }

  loadReservations() {
    const { user } = this.state;
    firestore.collection("reservations")
        .where('userEmail', '==', user.email)
        .orderBy('isActive', 'desc')
        .orderBy('startDate', 'desc')
        .get()
        .then((querySnapshot) => {
          let reservations = [];
            querySnapshot.forEach((doc) => {
              let reservation = doc.data();
              reservation.docId = doc.id;
              reservations.push(reservation);
            });
            return reservations;
        })
        .then(list => {
          this.setState({reservations: list});
        });
  }

  componentDidMount() {
    firebaseAppAuth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        this.setState({user});
        this.loadReservations();
        let profileRef = firestore.collection("profiles").doc(user.uid);
        profileRef.get().then(doc => {
            if (!doc.exists) {
                profileRef.set({credits: INIT_CREDIT_AMOUNT});
            }
          });
        firestore.collection("reservations")
            .where('userEmail', '==', user.email)
            .onSnapshot(querySnapshot => {
                this.loadReservations();
            }, err => {
              console.log(`Encountered error: ${err}`);
            });
        firestore.collection("profiles").doc(user.uid)
            .onSnapshot(doc => {
              if (doc.exists) {
                this.setState({credits: doc.data().credits});
              }
            }, err => {
              console.log(`Encountered error: ${err}`);
            });
      } else {
        this.setState({reservations : []});
      }
    });
  }

  handleSignInModalClose() {
    this.setState({signInModalShow : false});
  }

  handleSignInModalOpen() {
    this.setState({signInModalShow : true});
    this.setState({signInErrorMessage : ""});
  }

  handleReservationModalClose() {
    this.setState({reservationModalShow : false});
  }

  handleReservationModalOpen() {
    this.setState({reservationModalShow : true});
  }

  handleReservationSubmit() {
    this.loadReservations();
    this.handleReservationModalClose();
  }

  showCreateUser() {
    this.setState({showCreateUser: true});
  }

  showSignIn() {
    this.setState({showCreateUser: false});
  }

  getErrorMessage(errorCode) {
    if (errorCode === "auth/invalid-email") {
      return "Please check the email address";
    } else if (errorCode === "auth/user-not-found") {
      return "Please check email or password";
    } else if (errorCode === "auth/wrong-password") {
      return "Please check email or password";
    } else if (errorCode === "auth/weak-password") {
      return "Please enter a stronger password";
    } else if (errorCode === "auth/email-already-in-use") {
      return "Please use a different email address";
    }
    return "An error occurred. Please try again or contact support."
  }

  handleCreateUser(email, password) {
    this.setState({signInErrorMessage : ""});
    firebase.auth().createUserWithEmailAndPassword(email, password)
        .then(result => {
          let user = firebase.auth().currentUser;
          user.sendEmailVerification().then(unused => {
            this.setState({emailVerificationSent : true });
            this.showSignIn();
          }).catch(error => {
            this.setState({signInErrorMessage : "Could not sent verification email."});
          });
        }, error => {
            this.setState({signInErrorMessage : this.getErrorMessage(error.code)});
        });
  };

  handleSignInUser(email, password) {
    this.setState({signInErrorMessage : ""});
    firebase.auth().signInWithEmailAndPassword(email, password)
       .then(result => {this.handleSignInModalClose()}, error => {
            this.setState({signInErrorMessage : this.getErrorMessage(error.code)});
        });
  };

  render() {
    const {
      user,
      signOut,
      signInWithGoogle
    } = this.props;

    const handleSignInWithGoogle = () => {
    this.setState({signInErrorMessage : ""});
      signInWithGoogle()
       .then(result => {this.handleSignInModalClose()}, error => {
            this.setState({signInErrorMessage : this.getErrorMessage(error.code)});
        });
  };

    const {reservations} = this.state;
    let reservationRows = [];
    if (reservations) {
      for (let reservationIndex = 0; 
           reservationIndex < reservations.length; 
           reservationIndex++) {
        let reservation = reservations[reservationIndex];
        reservationRows.push(
            <Reservation 
                key={reservation.docId}
                docId={reservation.docId}
                onReservationChanged={this.loadReservations}
                firestore={firestore}
                reservation={reservation} />);
      }
    }
    let placeholderText = "You have no site checks yet.";
    if (user) {
      placeholderText = "Sign in to see your site checks.";  
    }  
    return (
      <Router>
        <div className="App">
          <header>
            <Navbar bg="dark" variant="dark" expand="md">
              <Navbar.Brand as={Link} to="/" >cmpcmpcmp.com</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse>
                <Nav className="mr-auto">
                  <NavItem eventkey={1} href="/">
                    <Nav.Link as={Link} to="/" >Home</Nav.Link>
                  </NavItem>
                  <NavItem eventkey={2} href="/console">
                    <Nav.Link as={Link} to="/console" >Console</Nav.Link>
                  </NavItem>
                  <NavItem eventkey={2} href="/faq">
                    <Nav.Link as={Link} to="/faq" >FAQ</Nav.Link>
                  </NavItem>
                </Nav>
                <Nav>
                  {
                    user &&
                    <NavItem>
                      <Link className="navButton creditsButton btn btn-success" role="button" to="/credits">
                        Credits: {this.state.credits} 
                      </Link>
                    </NavItem>
                  }
                  {
                    user
                      ? <NavItem>
                          <DropdownButton className="navButton" variant="light" 
                              id="dropdown-basic-button" title={user.displayName || user.email}>
                             <Dropdown.Item variant="light" onClick={signOut}>Sign out</Dropdown.Item>
                          </DropdownButton>
                        </NavItem>

                      : <NavItem><Button className="navButton" variant="light" onClick={this.handleSignInModalOpen}>
                          Sign in</Button>
                        </NavItem>
                  }
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <Modal show={this.state.signInModalShow} onHide={this.handleSignInModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Sign in to Create a Site Check</Modal.Title>
              </Modal.Header>
              <Modal.Body> 

                <Button variant="light" onClick={handleSignInWithGoogle}>
                    <img className="googleSignIn" alt="sign in with Google" src={googleSignIn} />
                    Sign in with Google</Button>
                <hr className="hr-text" data-content="OR" />
                <React.Fragment>
                  {this.state.signInErrorMessage !== "" && 
                      <Alert variant="danger">{this.state.signInErrorMessage}</Alert>}
                  {this.state.emailVerificationSent && 
                      <Alert variant="success">Please check your email inbox for a verification email. 
                          Click the enclosed link and then sign in here again.</Alert>}
                  {
                    this.state.showCreateUser ?
                      <div>
                        <UserForm onSubmit={this.handleCreateUser} submitLabel="Sign up"/>
                        <span className="signUpInLabel">Already have an account?</span> 
                        <Button variant="link" onClick={this.showSignIn}>Sign in</Button>
                      </div> 
                  : 
                      <div>
                        <UserForm onSubmit={this.handleSignInUser} submitLabel="Sign in" />
                        <span className="signUpInLabel">Don't have an account?</span>
                        <Button variant="link" onClick={this.showCreateUser}>Sign up</Button>
                      </div>
                  }
                </React.Fragment>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleSignInModalClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </header>
          <Switch>

            <Route
              path="/faq"
              render={(renderProps) => (
                <InfoPage />
              )} />

            <Route
              path="/credits"
              render={(renderProps) => (
                <div>
                { process.env.REACT_APP_LAUNCH_STATUS === "free" ?
                  <div className="aboutContainer">  
                    <Jumbotron>
                      If you ever run out of credits, contact  
                      <a href="mailto:info@cmpcmpcmp.com"> info@cmpcmpcmp.com</a>. The credit system is intended as 
                      a safeguard for the tool maintainer from incurring unexpected expenses. 
                    </Jumbotron>
                  </div>
                :
                  <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUB_KEY}>
                    <Elements>
                      <CheckoutForm firebaseAppAuth={firebaseAppAuth} />
                    </Elements>
                  </StripeProvider>
                }
                </div>
              )} />

            <Route
              path="/console"
              render={(renderProps) => (
              <div className="reservationContainer">
                <Modal show={this.state.reservationModalShow} onHide={this.handleReservationModalClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Create a Site Check</Modal.Title>
                  </Modal.Header>
                  <Modal.Body> 
                    <CampgroundForm firebaseAppAuth={firebaseAppAuth} 
                    firestore={firestore} onSubmit={this.handleReservationSubmit}/>
                  </Modal.Body>
                </Modal>
                <div>
                  <Container className="reservations">
                    <h4 className="reservationTitle">My Site Checks</h4>
                    <Button className="openReservationModalButton" 
                        variant="success" onClick={this.handleReservationModalOpen}>Create New
                    </Button>
                    {reservationRows.length > 0 ? 
                      reservationRows : 
                      <div className="reservationRowPlaceholder">{placeholderText}</div>}
                  </Container>
                </div>
              </div>
            )} />

            <Route
              path="/"
              render={(renderProps) => (
                <HomePage />
              )} />
          </Switch>
        </div>
      </Router>
    );
  }
}

// ========================================

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);