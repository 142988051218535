import Button from 'react-bootstrap/Button';
import React from 'react';
import compose from 'recompose/compose';
import withState from 'recompose/withState';

const Field = ({ children }) =>
  <React.Fragment>
    {children}
    <br />
  </React.Fragment>;

class UserInput extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {value: ''};
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange (e) {
    this.setState({value: e.target.value});
    this.props.onChange(e.target.value);  
  }

  render() {
    return (
      <input value={this.state.value} type={this.props.type} onChange={this.handleOnChange} />);
  }
}

const SubmitButton = props =>
  <Button variant="light" onClick={props.onClick}>{props.submitLabel}</Button>;

const UserForm = ({
  setEmail,
  setPassword,
  email,
  password,
  onSubmit,
  submitLabel
}) => (
  <React.Fragment>
    <Field>
      <span className="inputLabel">Email: </span><UserInput value={email} type="email" onChange={setEmail} />
    </Field>
    <Field>
      <span className="inputLabel">Password: </span><UserInput value={password} onChange={setPassword} type="password" />
    </Field>
    <SubmitButton submitLabel={submitLabel} onClick={() => onSubmit(email, password)} />
  </React.Fragment>
);

const withUserFormState = compose(
  withState('email', 'setEmail'),
  withState('password', 'setPassword'),
);

export default withUserFormState(UserForm);