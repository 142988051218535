import React, {Component} from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import {CardElement, injectStripe} from 'react-stripe-elements';

import './checkoutForm.css';

var PRODUCTS = [
    {'name': '30 Credits', 'price': '4.99 USD'}, 
    {'name': '60 Credits', 'price': '8.99 USD'}, 
    {'name': '200 Credits', 'price': '14.99 USD'}
];
class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inTransaction: false,
      paymentModalShow: false,
      productId: null,
      paymentErrorMessage: "",
      name: "",
      user: null
    };
    this.submit = this.submit.bind(this);
    this.paymentModalClose = this.paymentModalClose.bind(this);
    this.handleProductSelected = this.handleProductSelected.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
  }

  componentDidMount() {
    this.props.firebaseAppAuth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        this.setState({user});
      }
    });
  }

  paymentModalClose() {
    this.setState({paymentModalShow : false});
  }

  onNameChange(e) {
    this.setState({name : e.target.value});
  }

  handleProductSelected(productId) {
    this.setState({productId : productId});
    this.setState({paymentModalShow : true});
  }

  async submit(ev) {
  this.setState({inTransaction: true});
  let {token} = await this.props.stripe.createToken({name: this.state.name});
  if (!token) {
    this.setState({paymentErrorMessage: "Error completing purchase. Please check the credit card information."});
    return;
  }
  let data = {
    tokenId: token.id,
    userId: this.state.user.uid,
    productId: this.state.productId  
  }
  let response = await fetch("/api/charge", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { 'Content-type': 'application/json' }
  });

  if (response.ok) {
    this.setState({paymentModalShow: false});
  } else {
    this.setState({paymentErrorMessage: "Error completing purchase. Please check the credit card information."});
  }
  this.setState({inTransaction: false});
}

  render() {
    let product = PRODUCTS[this.state.productId];
    return (
      <div className="checkout">
        <Modal show={this.state.paymentModalShow} onHide={this.paymentModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Complete Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body> 
            {product &&
              <div className="productConfirmation">
                <h4>{product.name} for {product.price}</h4>
              </div>
            }
            {this.state.paymentErrorMessage !== "" && 
                <Alert variant="danger">{this.state.paymentErrorMessage}</Alert>}
            <div className="nameInput">
              <span>Name:</span><input type="text" value={this.state.name} onChange={this.onNameChange}/>
            </div>
            <CardElement />
            {this.state.inTransaction ? 
              <Button className="purchaseButton" variant="success" onClick={this.submit}> 
                <Spinner animation="border" />
              </Button> :
              <Button className="purchaseButton" variant="success" onClick={this.submit}> 
                  Purchase</Button>
            } 
          </Modal.Body>
        </Modal>
        <Card style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>30 Credits</Card.Title>
            <Card.Subtitle>$4.99</Card.Subtitle>
            <Card.Text>
              Good option for those looking to grab a campsite for 2 to 3 weekends or one week-long trip.
            </Card.Text>
            <Button variant="primary" onClick={() => this.handleProductSelected(0)}>Select</Button>
          </Card.Body>
        </Card>
        <Card style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>60 Credits</Card.Title>
            <Card.Subtitle>$8.99</Card.Subtitle>
            <Card.Text>
              Good option for those who camp regularly at popular campgrounds and relies on the tool to secure a reservation.
            </Card.Text>
            <Button variant="primary" onClick={() => this.handleProductSelected(1)}>Select</Button>
          </Card.Body>
        </Card>
        <Card style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title>200 Credits</Card.Title>
            <Card.Subtitle>$14.99</Card.Subtitle>
            <Card.Text>
              Good option for those who camp frequently at popular campgrounds and are looking for best value for dollar spent.
            </Card.Text>
            <Button variant="primary" onClick={() => this.handleProductSelected(2)}>Select</Button>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);