import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import React from 'react';

import moment from 'moment'
import 'firebase/firestore';

var CAMPGROUND_INIT_LIMIT = 5;
var CAMPGROUND_LIMIT_EXPANSION = 5;

class CampgroundForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      user: null,
      campgroundsData: null,
      startDate: new Date(),
      endDate: nextDate(new Date()),
      selectedCampgrounds: new Set(),
      filterType: "park",
      filterText: "",
      siteType: "any",
      limit: CAMPGROUND_INIT_LIMIT,
      errorMessage: null,
      credits: 0,
      creditCost: calculateCreditCost(new Date(), new Date(), nextDate(new Date()), 0)
    };
    this.createReservation = this.createReservation.bind(this);
    this.handleCampgroundSelectionChange = this.handleCampgroundSelectionChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadCampgrounds = this.loadCampgrounds.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFilterTypeChange = this.onFilterTypeChange.bind(this);
    this.onSiteTypeChange = this.onSiteTypeChange.bind(this);
    this.populateCreditCost = this.populateCreditCost.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleStartDateChange(date) {
    this.setState({startDate: date});
    let endDate = this.state.endDate;
    if (moment(this.state.endDate).diff(moment(date),'days') <= 0) {
      this.setState({endDate: nextDate(date)});
      endDate = nextDate(date);
    }
    this.populateCreditCost(date, endDate);
  }

  handleEndDateChange(date) {
    this.setState({endDate: date});
    let startDate = this.state.startDate;
    if (moment(this.state.startDate).diff(moment(date),'days') >= 0) {
      this.setState({startDate: prevDate(date)});
      startDate = prevDate(date);
    }
    this.populateCreditCost(startDate, date);
  }

  populateCreditCost(startDate, endDate) {
    let cost = calculateCreditCost(
        new Date(), startDate, endDate, this.state.selectedCampgrounds.size);
    this.setState({creditCost: cost});
  }

  handleCampgroundSelectionChange(campgroundId, selected) {
    if (selected) {
      this.state.selectedCampgrounds.add(campgroundId);  
    } else {
      this.state.selectedCampgrounds.delete(campgroundId);  
    }
    this.populateCreditCost(this.state.startDate, this.state.endDate);
  }

  componentDidMount() {
    this.loadCampgrounds(
      this.state.filterType, this.state.filterText, this.state.limit);
    this.props.firebaseAppAuth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        this.setState({user});
      }
      this.props.firestore.collection("profiles").doc(user.uid)
        .onSnapshot(doc => {
            this.setState({credits: doc.data().credits});
        }, err => {
          console.log(`Encountered error: ${err}`);
        });
    });
  }

  validate(startDate, endDate, selectedCampgrounds) {
    let validateResult = true;
    if (!this.state.user) {
      this.setState({ errorMessage : 'You must be signed in to create reservation check.'});
      validateResult = false;
    }
    if (moment(endDate).diff(moment(startDate),'days') <= 0) {
      this.setState({ errorMessage : 'Start date must be later than end date.'});
      validateResult = false;
    }
    if (moment(startDate).diff(moment(new Date()),'days') < 0) {
      this.setState({ errorMessage : 'Start date must be in the future.'});
      validateResult = false;
    }
    if (selectedCampgrounds.size <= 0) {
      this.setState({ errorMessage : 'Select at least one campground.'});
      validateResult = false;
    }
    if (calculateCreditCost(new Date(), startDate, endDate, selectedCampgrounds.size) > this.state.credits) {
      this.setState({ errorMessage : 'Not enough credits. Please get more.'});
      validateResult = false;
    }
    if (validateResult) {
      this.setState({ errorMessage : null});
    }
    return validateResult;
  }

  createReservation(startDate, endDate, selectedCampgrounds) {
    let valid = this.validate(startDate, endDate, selectedCampgrounds);
    if (!valid) {
      return;
    }
    let creditCost = calculateCreditCost(new Date(), startDate, endDate, selectedCampgrounds.size);
    let campgroundLookupPromises = [];
    selectedCampgrounds.forEach((campgroundId) => {
      campgroundLookupPromises.push(
          this.props.firestore.collection("campgrounds")
              .where('id', '==', campgroundId)
              .get()
              .then((querySnapshot) => {
                let campgroundIdToName = {};
                querySnapshot.forEach((doc) => {
                  campgroundIdToName.id = campgroundId;
                  campgroundIdToName.name = doc.data().name;
                });
                return campgroundIdToName;
              }));
    });
    Promise.all(campgroundLookupPromises)
        .then(campgroundIdToNameList => {
          let lookup = {}
          campgroundIdToNameList.forEach(item => {
            lookup[item.id] = item.name;
          });
          return lookup;
        })
        .then(campgroundIdToNameMap => {
            let allAddPromises = [];
            selectedCampgrounds.forEach((campgroundId) => {
              let addPromise = this.props.firestore.collection("reservations").add({
                  startDate: new Date(
                      startDate.getFullYear(), 
                      startDate.getMonth(), 
                      startDate.getDate()),
                  endDate: new Date(
                      endDate.getFullYear(), 
                      endDate.getMonth(), 
                      endDate.getDate()),
                  isActive: true,
                  campgroundId: campgroundId,
                  campgroundName: campgroundIdToNameMap[campgroundId] || "",
                  userEmail: this.state.user.email,
                  siteType: this.state.siteType,
                  snapshot: []
              })
              .then(ref => {
                return fetch('/api/reservation/confirmation?docId=' + ref.id)
                    .catch(function(error) {
                      console.error("Error adding document: ", error);
                    });
              })
              .catch(function(error) {
                  // Swallow it.
              });
              allAddPromises.push(addPromise);
            });
            return Promise.all(allAddPromises);
        })
        .then(result => {
          let profileRef = this.props.firestore.collection('profiles').doc(this.state.user.uid);
          return profileRef.get()
            .then(doc => {
              if (doc.exists) {
                  let profile = doc.data();
                  profile.credits = profile.credits || 0;
                  profile.credits -= creditCost;
                  profileRef.set(profile);
              }
            })
            .catch((err) => {
              console.log('Error getting documents', err);
            });
        }).then(unused => {this.props.onSubmit();});
  }

  loadCampgrounds(filterType, filterText, limit) {
    let campgroundsList = [];
    this.props.firestore.collection("campgrounds")
      .where(filterType, ">=", titleCase(filterText))
      .orderBy(filterType)
      .limit(limit)
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let campgroundsData = doc.data();
          campgroundsList.push(campgroundsData);
        });
      }).then(unused => {
        this.setState({campgroundsData : campgroundsList});
      });
  }

  handleSubmit = (e) => { 
    e.preventDefault();
    this.createReservation(
      this.state.startDate, 
      this.state.endDate, 
      this.state.selectedCampgrounds);
  }

  onFilterChange = (e) => {
    this.loadCampgrounds(this.state.filterType, e.target.value, CAMPGROUND_INIT_LIMIT);
    this.setState({limit: CAMPGROUND_INIT_LIMIT});
    this.setState({filterText: e.target.value});
  }
  
  increaseLimit = (e) => {
    let newLimit = this.state.limit + CAMPGROUND_LIMIT_EXPANSION;
    this.loadCampgrounds(this.state.filterType, this.state.filterText, newLimit);
    this.setState({limit: newLimit});
  }

  onFilterTypeChange = (e) => {
    this.loadCampgrounds(e.target.value, this.state.filterText, CAMPGROUND_INIT_LIMIT);
    this.setState({limit: CAMPGROUND_INIT_LIMIT});
    this.setState({filterType: e.target.value});
  }

  onSiteTypeChange = (e) => {
    this.setState({siteType: e.target.value});
  }

  render() {
    const {campgroundsData} = this.state;
    let rows = [];
    if (campgroundsData) {
      for (let campgroundIndex = 0; 
           campgroundIndex < campgroundsData.length; 
           campgroundIndex++) {
        let campground = campgroundsData[campgroundIndex];
        rows.push(
            <CampgroundSelect 
                onChange={this.handleCampgroundSelectionChange} 
                park={campground.park}
                key={campground.name} 
                name={campground.name} 
                id={campground.id} />);
      }
    }
    return (
        <form onSubmit={this.handleSubmit}>
        { this.state.errorMessage && <Alert key="campgroundCreateAlert" variant="danger">
          {this.state.errorMessage}</Alert>}
        <div>
          <span className="dateInputLabel">Check-in: </span>
          <DatePicker
            selected={this.state.startDate}
            onChange={this.handleStartDateChange} />
        </div>
        <div>
          <span className="dateInputLabel">Check-out: </span>
          <DatePicker 
            selected={this.state.endDate} 
            onChange={this.handleEndDateChange} />
        </div>
        <div className="campgroundFilter">
          <span>Site type: </span>
          <select value={this.state.siteType} onChange={this.onSiteTypeChange}>
            <option value="any">Any available</option>
            <option value="rv">Fits RV</option>
            <option value="cabin">Cabin</option>
          </select>
        </div>
        <div className="campgroundFilter">
          <span>Filter: </span>
          <select value={this.state.filterType} onChange={this.onFilterTypeChange}>
            <option value="park">Park name</option>
            <option value="name">Campground</option>
          </select>
          &nbsp;starts with&nbsp;
          <input type="text" placeholder="i.e. Yosemite" value={this.state.filterText} onChange={this.onFilterChange}/>
        </div>
        {rows}
        <Button variant="link" onClick={this.increaseLimit}>Show more</Button>
        <Button variant="success" className="createReservationButton" type="submit">Create</Button> 
        <span className="creditCost">Cost: {this.state.creditCost} Credits </span>
        </form>
    );
  }
}

class CampgroundSelect extends React.Component {
  
  constructor(props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange (e) {
    this.props.onChange(this.props.id, e.target.checked);  
  }

  render() {
    let campgroundRefUrl = "https://www.recreation.gov/camping/campgrounds/" + this.props.id; 
    return (
      <div>
        <label className="campgroundInput">
            <input name={this.props.id} 
               type="checkbox" 
               value={this.props.id} onChange={this.handleOnChange} />
            <a href={campgroundRefUrl} target="blank">{this.props.name}</a>
            <div className="campgroundSubtitle">
              Part of {this.props.park}
            </div>
       </label>
      </div>);
  }
}

function calculateCreditCost(now, startDate, endDate, numCampgrounds) {
  let sizeCost = moment(endDate).diff(moment(startDate),'days') * 3 * numCampgrounds;
  let durationCost = Math.round(moment(startDate).diff(moment(now),'days') * numCampgrounds * 1 / 2 );
  return sizeCost + durationCost;
}

function titleCase(str) {
  str = str.toLowerCase().split(' ');
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
  }
  return str.join(' ');
}

function nextDate(date) {
  return moment(date).add(1, 'day').toDate();
}

function prevDate(date) {
  return moment(date).add(-1, 'day').toDate();
}

export default CampgroundForm;